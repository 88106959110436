import * as React from "react";
import { Helmet } from "react-helmet";
import { Layout } from '../components';


// markup
const ConflictResolution = ({ location }) => {


    return (
        <Layout location={location}>

            <Helmet>
				<link href="/assets/css/privacy-policy--and--terms-of-service--and--conflict-resolution.css" rel="stylesheet" type="text/css" />
            </Helmet>



            <main data-name="layout" id={'conflict_resolution'} className={'conflict-resolution'}>
                <div className={'page-content container-fluid col-10 col-xl-8'}>

                    <div className={'title'}>Conflict Resolution</div>
                    <div className={'text-content'}>
                        This version was last updated on December the 14th <strong>2021</strong >.
                        <br /><br /><br />
                    </div>


                    {/* <div className={'subtitle'}>Conflict Resolution</div> */}
                    <div className={'text-content'}>
						Se, por qualquer motivo, o Utilizador não estiver satisfeito com o Serviço, poderá contactar a Ethernal | Ephemeral, para apresentar uma reclamação. As partes poderão em primeiro lugar procurar dirimir os litígios decorrentes do Contrato por acordo amigável. Se as partes não conseguirem chegar a acordo a este respeito, o litígio pode ser dirimido por um tribunal judicial competente, exceto se a lei aplicável no país de origem do Utilizador for mais benéfica para o Utilizador. Enquanto consumidor, o Utilizador tem o direito de submeter a questão a um tribunal judicial do local onde o Utilizador residir.<br /><br />
						O Utilizador pode igualmente optar por apresentar, numa primeira instância, uma reclamação à autoridade nacional competente para a resolução de litígios de consumo:<br /><br />
						ASAE - Autoridade de Segurança Alimentar e Económica - <a href="https://www.asae.gov.pt/" target="_blank" rel="noreferrer">https://www.asae.gov.pt/</a><br /><br />
						ou<br /><br />
						Direção-Geral do Consumidor - <a href="https://www.consumidor.gov.pt/" target="_blank" rel="noreferrer">https://www.consumidor.gov.pt/</a><br /><br />
						Além disso, o litígio pode ser dirimido por um centro de arbitragem de conflitos de consumo.<br /><br />
						<br />
						Em Lisboa existem dois centros:<br /><br />
						Centro de Arbitragem de Conflitos de Consumo de Lisboa (CACCL): <a href="http://www.centroarbitragemlisboa.pt/" target="_blank" rel="noreferrer">http://www.centroarbitragemlisboa.pt/</a><br /><br />
						ou<br /><br />
						Centro de Arbitragem da Universidade Autónoma de Lisboa (CAUAL): <a href="https://arbitragem.autonoma.pt/" target="_blank" rel="noreferrer">https://arbitragem.autonoma.pt/</a>
                    </div>

                </div>

            </main >
        </Layout >
    )
}

export default ConflictResolution
